import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'

import { ClockFormatContext } from '@eventtemple/eventtemple-ui'
import ThemeProvider from '@repo/theme'
import { SnackbarProvider } from 'notistack'

import use24HourClock from 'hooks/use24HourClock'
import useDateFnsLocale from 'hooks/useDateFnsLocale'

import type { ReactNode } from 'react'

export const MAX_SNACKS = 3

interface UiProviderProps {
  children: ReactNode
}

const UiProvider = (props: UiProviderProps): JSX.Element => {
  const { children } = props
  const currentLocale = useDateFnsLocale()
  const use24HourTime = use24HourClock()

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
        <ClockFormatContext.Provider value={use24HourTime}>
          <SnackbarProvider maxSnack={MAX_SNACKS}>{children}</SnackbarProvider>
        </ClockFormatContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default UiProvider
