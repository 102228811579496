import React, { forwardRef } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio
} from '@mui/material'

import type { RadioGroupProps } from 'components/common/buttons/RadioGroup'

import type { ValueLabelPair, ValueNodePair } from '@repo/et-types'

const rowRadioGroupStyles = {
  '.MuiFormGroup-root': { flex: 1, justifyContent: 'space-evenly' },
  '.MuiFormControlLabel-root': { margin: '0 auto 0 0 !important' }
}

const RadioGroup = forwardRef(
  (
    {
      label,
      values,
      name,
      required,
      disabled,
      isError,
      errorMessage,
      helperText,
      FormControlProps = {},
      RadioProps = {},
      value,
      ...props
    }: RadioGroupProps,
    ref
  ) => {
    const id = `${name}-radio-group`

    return (
      <FormControl
        required={required}
        disabled={disabled}
        error={isError}
        focused={false}
        sx={{ mt: label ? '0.5rem' : '0', ...(props?.row ? rowRadioGroupStyles : {}) }}
        {...FormControlProps}>
        <Box mb=".25rem">
          {label && (
            <FormLabel
              sx={{ fontWeight: 'bold', color: disabled ? 'text.disabled' : 'text.primary' }}>
              {label}
            </FormLabel>
          )}
          {helperText && <FormHelperText sx={{ m: '0' }}>{helperText}</FormHelperText>}
        </Box>
        <MuiRadioGroup {...props} value={value}>
          {values.map((e: ValueLabelPair | ValueNodePair, index: number) => (
            <Box key={index} display="flex" flexDirection="column">
              <FormControlLabel
                key={index}
                value={e.value}
                control={<Radio {...RadioProps} />}
                label={
                  <>
                    {typeof e.label === 'string' ? (
                      <FormLabel
                        sx={{
                          pointerEvents: 'none',
                          color: disabled ? 'text.disabled' : 'text.primary'
                        }}>
                        {e.label}
                      </FormLabel>
                    ) : (
                      e.label
                    )}
                    {e.helperText && (
                      <FormHelperText sx={{ m: '0' }}>{e.helperText}</FormHelperText>
                    )}
                  </>
                }
                id={id}
                ref={ref}
              />
              {value === e.value && e.children && (
                <FormControlLabel
                  value={e.value}
                  control={<Radio sx={{ visibility: 'hidden' }} />}
                  sx={{ color: disabled ? 'text.disabled' : 'text.primary', flex: 1 }}
                  label={e.children}
                />
              )}
            </Box>
          ))}
        </MuiRadioGroup>
        {isError && errorMessage && (
          <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>
        )}
      </FormControl>
    )
  }
)

RadioGroup.displayName = 'RadioGroup'

export default RadioGroup
