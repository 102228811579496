import { type ReactElement, useCallback, useEffect } from 'react'

import { LicenseInfo } from '@mui/x-license'

import dynamic from 'next/dynamic'

import Head from 'next/head'

import nextI18NextConfig from '@repo/next-i18next-config'
import { appWithTranslation } from 'next-i18next'

import { hotjar } from 'react-hotjar'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY ?? '')

import MainLayout from 'components/layout/MainLayout'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'styles/application.scss'

const NewRelicAgent = dynamic(() => import('@repo/new-relic-agent/NewRelicAgent'), { ssr: false })

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => JSX.Element
  isSettingPage?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  useEffect(() => {
    const HJID = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID ?? '0')
    const HJSV = parseInt(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION ?? '0')

    if (HJID && HJSV) hotjar.initialize({ id: HJID, sv: HJSV })
  }, [])

  // If we have a scenario where we want to have a different layout for the overall application
  // on a particular page, we can define getLayout function on that component. If getLayout is not defined,
  // MainLayout is the default layout

  const getLayout = useCallback(
    () =>
      Component.getLayout ? (
        Component.getLayout(<Component {...pageProps} />)
      ) : (
        <MainLayout {...pageProps}>
          <Component {...pageProps} />
        </MainLayout>
      ),
    [Component, pageProps]
  )

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" sizes="64x64" />
        <link rel="icon" type="image/x-icon" href="/favicon-48x48.ico" sizes="48x48" />
        <link rel="icon" type="image/x-icon" href="/favicon-32x32.ico" sizes="32x32" />
        <link rel="icon" type="image/x-icon" href="/favicon-16x16.ico" sizes="16x16" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />

        {(process.env.NODE_ENV === 'development' || process.env.VERCEL_ENV === 'preview') && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script
            data-recording-token="mUGaRnPfwm7Rnn0oPuSNUlJmPtLyibzlipubvSDH"
            data-is-production-environment="false"
            src="https://snippet.meticulous.ai/v1/meticulous.js"
          />
        )}
      </Head>
      {getLayout()}
      <NewRelicAgent />
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
