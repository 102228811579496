import type { HTMLAttributes, KeyboardEvent, ReactNode, SyntheticEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ListItem, Autocomplete as MUIAutocomplete, TextField } from '@mui/material'

import { Chip } from '@mui/material'
import { useDebounceCallback } from 'usehooks-ts'

import type { AutocompleteProps } from 'components/common/inputs/Autocomplete/Autocomplete.types'
import ReadOnly from 'components/common/inputs/ReadOnly'

import Loader from 'components/common/layout/Loader'

import { useDidMount } from 'hooks/useDidMount'

import { DEFAULT_DEBOUNCE } from 'utils/search'

import type {
  AutocompleteInputChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState
} from '@mui/material'

import type { ValueLabelPair } from '@repo/et-types'

const defaultRole = 'comboBox'
const newId = 'new'

const defaultNone: ValueLabelPair = { label: 'None', value: null }

const Autocomplete = <
  T extends ValueLabelPair = ValueLabelPair,
  M extends boolean | undefined = undefined,
  D extends boolean | undefined = undefined,
  F extends boolean | undefined = undefined
>({
  canCreateNew,
  createNewMessage,
  defaultValue,
  disableClearable,
  errorMessage,
  ExistingValueChip,
  freeSolo,
  helperText,
  inputRef,
  isError = false,
  isLoading = false,
  label,
  multiple,
  name,
  NewValueChip,
  noValue = false,
  onBlur,
  onChange,
  onCreateNew,
  onSearchChange,
  placeholder,
  readOnly,
  readOnlyInput = false,
  ReadOnlyProps,
  renderOption,
  required,
  shouldShowDefaultValueOptions = true,
  shouldShowNone = false,
  size,
  TextFieldProps,
  value,
  values,
  ...props
}: AutocompleteProps<T, M, D, F>) => {
  const didMount = useDidMount()

  const initialValue = defaultValue ? defaultValue : value || multiple ? [] : null
  const [currentValue, setCurrentValue] = useState<T | T[] | null>(initialValue)

  const [options, setOptions] = useState<T[]>([])
  const [autocompleteInputValue, setAutoCompleteInputValue] = useState<string>('')

  const autocompleteValue = useMemo(() => {
    if (
      (!currentValue || (multiple && (currentValue as T[])?.length < 1)) &&
      defaultValue &&
      !didMount
    ) {
      return defaultValue
    }

    return currentValue || null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, currentValue])

  const debouncedOnSearchChange = useDebounceCallback(
    onSearchChange ? onSearchChange : () => null,
    DEFAULT_DEBOUNCE
  )

  const id = `${name}-autocomplete`

  useEffect(() => {
    if (values) {
      const singleOptions = () => {
        const finalOptions = [...values]

        if (shouldShowDefaultValueOptions) {
          if (
            defaultValue &&
            !Array.isArray(defaultValue) &&
            !finalOptions.find((op) => op.value === defaultValue?.value)
          ) {
            finalOptions.unshift(defaultValue)
          }
        }

        if (shouldShowNone) finalOptions.unshift(defaultNone as T)

        return finalOptions
      }

      const multipleOptions = () => {
        const finalOptions = [...values]

        if (shouldShowDefaultValueOptions) {
          if (defaultValue && Array.isArray(defaultValue)) {
            defaultValue.forEach((item) => {
              if (!finalOptions.find((op) => op.value === item?.value) && item) {
                finalOptions.unshift(item)
              }
            })
          }
        }

        if (shouldShowNone) finalOptions.unshift(defaultNone as T)

        return finalOptions
      }

      if (!multiple) setOptions(singleOptions())
      else setOptions(multipleOptions())
    }
  }, [values, defaultValue, multiple, shouldShowDefaultValueOptions, shouldShowNone])

  // Handle when the value changes from outside the component
  useEffect(() => {
    if (!didMount) return

    if (!value) setCurrentValue(multiple ? [] : null)
    else {
      if (multiple) {
        const newValue: T[] = (value as string[]).map((val) => {
          const option = options.find((op) => String(op.value) === String(val))

          if (option) return option as T
          else return { value: val, label: val } as T
        })

        setCurrentValue(newValue)
      } else {
        const option = options.find((op) => op.value === value)

        if (option) setCurrentValue(option)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, multiple])

  const getOptionLabel = (option?: T | string) => {
    if (option) {
      if (typeof option === 'string') return option
      else if (option?.label) return option.label
    }

    return ''
  }

  const getOptionDisabled = (option: T) =>
    option && 'disabled' in option && typeof option.disabled !== 'undefined'
      ? option.disabled
      : false

  const handleOnChange = (_: SyntheticEvent, data: T | T[]) => {
    if (!Array.isArray(data) && data?.value === newId) {
      data.label = data.inputValue
      if (onCreateNew) onCreateNew(data)
    }

    const newValue =
      multiple && Array.isArray(data) ? data.map((item: T) => item?.value) : (data as T)?.value

    if (!noValue) {
      setCurrentValue(data)
      if (!multiple) setAutoCompleteInputValue((data as T)?.label || '')
    }

    return onChange((newValue as string & string[]) || null)
  }

  const handleFilterOptions = (options: T[], state: FilterOptionsState<T>): T[] => {
    const inputValue = state.inputValue
    const finalOptions = [...options]

    if (canCreateNew && inputValue.length > 0) {
      finalOptions.unshift({
        label: `${createNewMessage || 'Create:'} ${inputValue}`,
        inputValue: inputValue,
        value: newId
      } as T)
    }

    // if onSearchChange is defined, it means that the parent component is handling filtering
    if (onSearchChange) return finalOptions
    else {
      return finalOptions.filter((option) => {
        if (!inputValue) return true

        const label = option.label.toLowerCase()
        const optionValue = String(option.value)?.toLowerCase()

        return (
          label.includes(inputValue.toLowerCase()) ||
          optionValue?.includes(inputValue.toLowerCase())
        )
      })
    }
  }

  const getOptionKey = useCallback(
    (option: string | T) => (typeof option === 'string' ? option : String(option.value)),
    []
  )

  const inputValue = useMemo(() => {
    if (multiple) return undefined

    return autocompleteInputValue
  }, [autocompleteInputValue, multiple])

  const handleOnInputChange = useCallback(
    (_, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (onSearchChange) {
        if (reason === 'input' || reason === 'clear') debouncedOnSearchChange(newInputValue || '')
      }

      setAutoCompleteInputValue(newInputValue)
    },
    [debouncedOnSearchChange, onSearchChange]
  )

  const isOptionEqualToValue = useCallback(
    (option: T, option2: T) => String(option.value) === String(option2.value),
    []
  )

  const handleOptionRender = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement> & { key: number },
      option: T,
      state: AutocompleteRenderOptionState,
      ownerState: AutocompleteOwnerState<T, M, D, F>
    ) => {
      const index: number = props['data-option-index']
      const finalProps = { ...props, 'data-testid': `${id}-option-${index}` }

      return (
        <ListItem {...finalProps} key={index}>
          {renderOption ? renderOption(props, option, state, ownerState) : option.label}
        </ListItem>
      )
    },
    [id, renderOption]
  )

  const getChip = useCallback((): ReactNode | undefined => {
    if (canCreateNew) {
      if (!Array.isArray(currentValue) && currentValue?.value === 'new') {
        return NewValueChip || <Chip variant="filled" label="New" color="success" />
      } else if (currentValue) {
        return ExistingValueChip || <Chip variant="filled" label="Existing" color="primary" />
      }
    }

    return <></>
  }, [canCreateNew, currentValue, NewValueChip, ExistingValueChip])

  const getEndAdornment = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <>
        {getChip()}
        {isLoading ? <Loader data-testid="autocomplete-loading" color="inherit" size={20} /> : null}
        {/* @ts-expect-error -- MUI doesn't cast the slot props properly here. */}
        {TextFieldProps?.slotProps?.input?.endAdornment}
        {params.InputProps?.endAdornment}
      </>
    ),
    [getChip, isLoading, TextFieldProps]
  )

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') event.stopPropagation()
  }, [])

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const slotProps = {
        ...TextFieldProps?.slotProps,
        input: {
          ...params.InputProps,
          ...TextFieldProps?.slotProps?.input,
          readOnly: readOnlyInput,
          endAdornment: getEndAdornment(params)
        }
      }

      return (
        <TextField
          {...TextFieldProps}
          {...params}
          size={size}
          onKeyDown={onKeyDown}
          slotProps={slotProps}
          inputRef={inputRef}
          label={label}
          error={isError}
          helperText={isError ? errorMessage : helperText}
          placeholder={placeholder}
          required={required}
        />
      )
    },
    [
      inputRef,
      label,
      onKeyDown,
      getEndAdornment,
      TextFieldProps,
      isError,
      errorMessage,
      required,
      placeholder,
      helperText,
      readOnlyInput,
      size
    ]
  )

  const shouldDisableClearable = useMemo<D>(
    () => (Boolean(disableClearable) || Boolean(multiple)) as D,
    [disableClearable, multiple]
  )

  if (readOnly) {
    const readOnlyValue = Array.isArray(autocompleteValue)
      ? autocompleteValue?.map((val) => {
          if (typeof val === 'string' || typeof val === 'number') return val
          else if (val?.label) return val.label
        })
      : (autocompleteValue as T)?.label

    return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
  }

  return (
    <MUIAutocomplete<T, M, D, F>
      selectOnFocus
      disableCloseOnSelect={multiple}
      id={id}
      freeSolo={freeSolo}
      disableClearable={shouldDisableClearable}
      role={defaultRole}
      options={options}
      filterOptions={handleFilterOptions}
      inputValue={inputValue}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      getOptionDisabled={getOptionDisabled}
      onInputChange={handleOnInputChange}
      multiple={multiple}
      renderInput={handleRenderInput}
      // @ts-expect-error -- MUI doesn't allow us to override the type here
      // the way we need to, so we need to ignore the error.
      value={autocompleteValue}
      renderOption={handleOptionRender}
      // @ts-expect-error -- MUI doesn't allow us to override the type here
      // as we need, so we need to ignore the error.
      onChange={handleOnChange}
      onBlur={onBlur}
      {...props}
    />
  )
}

export default Autocomplete
