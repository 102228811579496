import React, { useState } from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'
import type { FormTimePickerOffsetProps } from 'components/forms/FormTimePickerOffset/FormTimePickerOffset.types'

const getSecondsSinceMidnight = (date: Date): number =>
  date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()

const getDateFromSeconds = (seconds: number): Date => {
  const date = new Date()

  date.setHours(0, 0, 0, 0)
  date.setSeconds(isNaN(Number(seconds)) ? 0 : Number(seconds))
  return date
}

const FormTimePickerOffset = ({
  name,
  required,
  slotProps,
  onValueChange,
  ...props
}: FormTimePickerOffsetProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const defaultValue = lodashGet(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const timeValue = value == 0 || value ? getDateFromSeconds(value) : null

        return (
          <TimePicker
            {...props}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            disableOpenPicker
            inputRef={ref}
            name={name}
            error={isError}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            onChange={(time) => {
              const seconds = getSecondsSinceMidnight(time)

              onValueChange?.(seconds, name)
              onChange(seconds)
            }}
            slotProps={{
              ...slotProps,
              textField: {
                onBlur,
                onClick: () => setIsOpen((prev) => !prev),
                ...slotProps?.textField
              }
            }}
            value={timeValue}
          />
        )
      }}
    />
  )
}

export default FormTimePickerOffset
