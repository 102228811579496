import React, { useMemo } from 'react'

import { LoadingButton } from '@mui/lab'

import { Button, Grid2 as Grid, Paper } from '@mui/material'

import type { DialogFormFooterProps } from 'components/forms/DialogForm/DialogForm.types'

import { DIALOG_TYPES } from 'types/dialog'

/**
 * The footer component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogFormFooter = ({
  discardButtonLabel,
  discardButtonProps,
  isLoading = false,
  onDiscard = undefined,
  onSubmit = undefined,
  reverseButtons = false,
  submitButtonLabel = undefined,
  variant = DIALOG_TYPES.DRAWER,
  submitButtonProps
}: DialogFormFooterProps) => {
  const isDrawer = variant === DIALOG_TYPES.DRAWER

  const padding = useMemo(() => {
    if (isDrawer) return { px: 4, py: 2 }

    return { px: 2, py: 2 }
  }, [isDrawer])

  const submitButtonMessage = submitButtonLabel || 'Submit'

  const discardButtonMessage = discardButtonLabel || 'Discard'

  const footerStyles = (theme) => ({
    bgcolor: theme.palette.background.paper,
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 1200,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: reverseButtons ? 'row-reverse' : 'row',
    ...padding
  })

  return (
    <Grid
      container
      square
      component={Paper}
      variant={isDrawer ? 'outlined' : 'elevation'}
      sx={footerStyles}
      data-testid="DialogFormFooter"
      columnSpacing={2}>
      {onDiscard && (
        <Grid>
          <Button variant="outlined" onClick={onDiscard} {...discardButtonProps}>
            {discardButtonMessage}
          </Button>
        </Grid>
      )}
      {onSubmit && (
        <Grid>
          <LoadingButton
            onClick={onSubmit}
            variant="contained" // by default submit button is contained
            color="primary"
            loading={isLoading}
            {...submitButtonProps}>
            {submitButtonMessage}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  )
}

export default DialogFormFooter
