import React from 'react'

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import type { DatePickerProps } from 'components/common/date/DatePicker'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDate } from 'hooks/useFormatDate'

const DatePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DatePickerProps) => {
  const { format, formatDate } = useFormatDate()

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter
        ? readOnlyFormatter(readOnlyValue)
        : formatDate(readOnlyValue, format)
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUIDatePicker
      {...props}
      value={value}
      label={label}
      format={format}
      slotProps={{
        ...slotProps,
        textField: (state) => ({
          error,
          helperText: error ? errorMessage : helperText,
          fullWidth,
          ...(typeof slotProps?.textField === 'function'
            ? slotProps.textField(state)
            : slotProps?.textField)
        })
      }}
    />
  )
}

export default DatePicker
