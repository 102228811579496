import React from 'react'

import { TimePicker as MUITimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro'

import { format, isValid } from 'date-fns'

import type { TimePickerProps } from 'components/common/date/TimePicker'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDateTime } from 'hooks/useFormatDate'

const TimePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: TimePickerProps) => {
  const { use24HourClock } = useFormatDateTime()

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter ? readOnlyFormatter(readOnlyValue) : format(readOnlyValue, 'h:mm a')
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUITimePicker
      {...props}
      value={value}
      label={label}
      ampm={!use24HourClock}
      orientation="landscape"
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock
      }}
      slotProps={{
        ...slotProps,
        textField: (state) => ({
          error,
          helperText: error ? errorMessage : helperText,
          fullWidth,
          ...(typeof slotProps?.textField === 'function'
            ? slotProps.textField(state)
            : slotProps?.textField)
        })
      }}
    />
  )
}

export default TimePicker
