// Stores reusable route paths, if a path ever changes, it can be changed here and will be reflected everywhere
// instead of needing to find and replace every instance of the path.
enum Routes {
  ROOT = '/',
  // Main App Routes
  ACCOUNTS = '/accounts',
  ACCOUNT = '/accounts/[id]',
  AUTOMATIONS = '/automations',
  BOOKINGS = '/bookings',
  BOOKING = '/bookings/[id]',
  BOOKING_CALENDAR = '/booking_calendar',
  EVENT_DIARY = '/event_diary',
  TASK_CALENDAR = '/task_calendar',
  CONTACTS = '/contacts',
  CONTACT = '/contacts/[id]',
  DASHBOARD = '/dashboard',
  EVENTS = '/events',
  EVENT = '/events/[id]',
  GROUPS = '/groups',
  GRC = '/grc',
  INVOICES = '/invoices',
  INVOICE = '/invoices/[id]',
  DOCUMENTS = '/documents',
  DOCUMENT = '/documents/[id]',
  ORGANIZATION_RFPS = '/organization_rfps',
  ORGANIZATION_RFP = '/organization_rfps/[id]',
  ORGANIZATION_RFP_PROPOSAL = '/organization_rfps/[id]/proposal',
  PROPOSALS = '/proposals',
  PROPOSAL = '/proposals/[id]',
  MAIL = '/mail',
  RFPS = '/rfps',
  RFP = '/rfps/[id]',
  RFP_VIEW = '/rfps/[id]/view/[organization_rfp_id]',
  SCHEDULED_PAYMENTS = '/scheduled_payments',
  TASKS = '/tasks',
  TEXT_MESSAGES = '/text_messages',
  // OAuth Routes
  OAUTH_AUTHORIZE = '/oauth/authorize',
  // Reports Routes
  REPORTS = '/reports',
  REPORTS_ACCOUNT_LIST = '/reports/account_list',
  REPORTS_ACTIVITY_LIST = '/reports/activity_list',
  REPORTS_AUTOMATION_STATS = '/reports/automation_stats',
  REPORTS_BOOKING_FINANCIAL = '/reports/booking_financial',
  REPORTS_BOOKING_GRAPHS = '/reports/booking_graphs',
  REPORTS_BOOKING_LIST = '/reports/booking_list',
  REPORTS_CATERING_PACE = '/reports/catering_pace',
  REPORTS_CONTACT_LIST = '/reports/contact_list',
  REPORTS_DAILY_EVENTS = '/reports/daily_events',
  REPORTS_DAILY_EVENTS_WITH_DETAILS = '/reports/daily_events_with_details',
  REPORTS_EMAIL_TEMPLATE_PERFORMANCE = '/reports/email_template_performance',
  REPORTS_EVENT_LIST = '/reports/event_list',
  REPORTS_EVENT_SALES_PRODUCTIVITY = '/reports/event_sales_productivity',
  REPORTS_GROUP_LIST = '/reports/group_list',
  REPORTS_GROUP_PACE = '/reports/group_pace',
  REPORTS_GROUP_PICKUP_BY_SALES_PERSON = '/reports/group_pickup_by_sales_person',
  REPORTS_GROUP_SALES_PRODUCTIVITY = '/reports/group_sales_productivity',
  REPORTS_INVOICE_LIST = '/reports/invoice_list',
  REPORTS_LINE_ITEM_QUANTITY = '/reports/line_item_quantity',
  REPORTS_LINE_ITEM_LIST = '/reports/line_items',
  REPORTS_LINE_ITEM_INVOICE_LIST = '/reports/line_item_invoice_list',
  REPORTS_LINE_ITEM_BOOKING_LIST = '/reports/line_item_booking_list',
  REPORTS_LINE_ITEM_EVENT_LIST = '/reports/line_item_event_list',
  REPORTS_PAYMENT_LIST = '/reports/payment_list',
  REPORTS_TASK_GRAPH = '/reports/task_graph',
  REPORTS_TASK_LIST = '/reports/task_list',
  REPORTS_TASK_PRODUCTIVITY = '/reports/task_productivity',
  REPORTS_TOP_ACCOUNTS = '/reports/top_accounts',
  REPORTS_TOP_KEY_ACCOUNTS = '/reports/top_key_accounts',

  // Auth Routes
  AUTH_LOGIN = '/auth/login',
  // Settings Routes
  SETTINGS = '/settings',
  SETTINGS_ACCOUNT_TYPES = '/settings/setup_values/account_types',
  SETTINGS_API_INTEGRATIONS = '/settings/integrations/api',
  SETTINGS_ASSETS = '/settings/assets', // assets is renamed to files in NFE
  SETTINGS_AUTOMATION_SEQUENCES = '/settings/automation_sequences',
  SETTINGS_BILLING_SETTINGS = '/settings/billing_settings',
  SETTINGS_BOOKING_TYPES = '/settings/setup_values/booking_types',
  SETTINGS_BUDGETS = '/settings/budgets',
  SETTINGS_CALENDAR_INTEGRATIONS = '/settings/integrations/calendar',
  SETTINGS_CUSTOM_FIELDS = '/settings/custom_fields',
  SETTINGS_DOCUMENT_TEMPLATE_LIBRARY = '/settings/document_template_library',
  SETTINGS_DOCUMENT_TEMPLATES = '/settings/document_templates',
  SETTINGS_DOCUMENT_TEMPLATE = '/settings/document_templates/[id]',
  SETTINGS_DOCUMENT_TYPES = '/settings/setup_values/document_types',
  SETTINGS_EMAIL = '/settings/email',
  SETTINGS_EMAIL_TEMPLATES = '/settings/email_templates',
  SETTINGS_EMAIL_TEMPLATES_CREATE = '/settings/email_templates/new',
  SETTINGS_EMAIL_TEMPLATES_EDIT = '/settings/email_templates/:id',
  SETTINGS_SETUP_STYLES = '/settings/setup_values/setup_styles',
  SETTINGS_EVENT_TYPES = '/settings/setup_values/event_types',
  SETTINGS_FILES = '/settings/files',
  SETTINGS_GUEST_PORTAL = '/settings/guest_portal',
  SETTINGS_IMPORTS = '/settings/imports',
  SETTINGS_INTAKE_FORM = '/settings/intake_forms/:id',
  SETTINGS_INTAKE_FORMS = '/settings/intake_forms',
  SETTINGS_INTAKE_FORMS_SETTINGS = '/intake_form_settings', // intake forms settings is renamed to intake forms in NFE
  SETTINGS_LOCATIONS = '/settings/locations',
  SETTINGS_LOST_REASONS = '/settings/setup_values/lost_reasons',
  SETTINGS_MARKET_SEGMENTS = '/settings/setup_values/market_segments',
  SETTINGS_MARKETPLACE = '/settings/marketplace',
  SETTINGS_MENU_ITEMS = '/settings/menu_items',
  SETTINGS_MENU_CATEGORIES = '/settings/menu_categories',
  SETTINGS_MENUS = '/settings/menus',
  SETTINGS_OAUTH2_APPLICATION = '/settings/oauth2_applications/:id',
  SETTINGS_OAUTH2_APPLICATIONS = '/settings/oauth2_applications',
  SETTINGS_ORGANIZATION_OVERVIEW = '/settings/overview',
  SETTINGS_ORGANIZATION_PREFERENCES = '/settings/preferences',
  SETTINGS_ORGANIZATIONS = '/settings/organizations',
  SETTINGS_PACKAGES = '/settings/packages',
  SETTINGS_PAYMENT_TYPES = '/settings/setup_values/payment_types',
  SETTINGS_PIPELINES = '/settings/pipelines',
  SETTINGS_PMS_INTEGRATIONS = '/settings/integrations/pms',
  SETTINGS_PMS_PROVIDER = '/settings/pms_provider', // pms provider is renamed to pms integrations in NFE
  SETTINGS_PROPOSAL_TEMPLATES = '/settings/proposal_templates',
  SETTINGS_PROPOSAL_TEMPLATE = '/settings/proposal_templates/[id]',
  SETTINGS_REFERRAL_SOURCES = '/settings/setup_values/referral_sources',
  SETTINGS_REVENUE_CATEGORIES = '/settings/setup_values/revenue_categories',
  SETTINGS_ROOM_PICKUPS = '/settings/room_pickups',
  SETTINGS_ROOM_TYPES = '/settings/room_types',
  SETTINGS_ROOMS = '/settings/rooms', // room types is renamed to rooms in NFE
  SETTINGS_SETUP_OVERVIEW = '/settings/setup_overview',
  SETTINGS_SETUP_VALUES = '/settings/setup_values',
  SETTINGS_SPACE_CLOSURE_REASONS = '/settings/setup_values/space_closure_reasons',
  SETTINGS_SPACES = '/settings/spaces',
  SETTINGS_STORE_FRONTS = '/settings/store_fronts',
  SETTINGS_STRIPE_INTEGRATIONS = '/settings/integrations/stripe',
  SETTINGS_SUBSCRIPTION = '/settings/subscription',
  SETTINGS_TASK_TYPES = '/settings/setup_values/task_types',
  SETTINGS_TAXES = '/settings/taxes',
  SETTINGS_TEXT = '/settings/settings/text',
  SETTINGS_TEXT_TEMPLATES = '/settings/text_templates',
  SETTINGS_TEXT_TEMPLATES_CREATE = '/settings/text_templates/new',
  SETTINGS_TEXT_TEMPLATES_EDIT = '/settings/text_templates/:id',
  SETTINGS_TEXTING = '/settings/texting',
  SETTINGS_USER_PROFILE = '/settings/user_profile',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_WEBHOOKS_INTEGRATIONS = '/settings/integrations/webhooks',
  SETTINGS_WORKFLOW_TEMPLATES = '/settings/workflow_templates',
  SETTINGS_ZAPIER_INTEGRATIONS = '/settings/integrations/zapier',

  // public routes
  PUBLIC_PROPOSALS = '/public/proposals'
}

export default Routes
