import { useMemo, useState } from 'react'

import {
  AccountCircle,
  AccountTree,
  Adjust,
  Api,
  Apps,
  ArrowBack,
  AttachMoney,
  AutoAwesomeMotion,
  Bolt,
  Calculate,
  CreditCard,
  DeveloperBoard,
  DynamicForm,
  EventAvailable,
  Hotel,
  ImportExport,
  Info,
  InsertDriveFile,
  Inventory,
  LabelImportant,
  Link,
  LocationCity,
  Mail,
  MeetingRoom,
  PermMedia,
  Public,
  RequestQuote,
  Restaurant,
  RoomService,
  School,
  Smartphone,
  SupervisorAccount,
  TextFields,
  Tune
} from '@mui/icons-material'

import { Box, Button } from '@mui/material'

import NextLink from 'next/link'

import { useTranslation } from 'next-i18next'

import { SearchInput } from 'components/views/filters/SearchInput'
import usePermissions from 'hooks/permissions/usePermissions'
import useSetupValueOptions from 'hooks/setupValues/useSetupValueOptions'
import useLegacyAppUrl from 'hooks/useLegacyAppUrl'
import useChainContextStore from 'store/chainContext'
import Routes from 'types/enums/routes'
import { TranslationNamespaces } from 'types/enums/tNamespaces'

import type { SideNavItem } from 'hooks/common/layout/sideNav/useSideNav'

const useSettingSideNavItems = (): SideNavItem[] => {
  const [search, setSearch] = useState('')

  const { selectedOrganization } = useChainContextStore((state) => ({
    selectedOrganization: state.selectedOrganization
  }))
  const { hasRoutePermissions } = usePermissions()
  const legacyAppUrl = useLegacyAppUrl()

  const { t } = useTranslation([
    TranslationNamespaces.COMMON,
    TranslationNamespaces.PERSONAL_SETTINGS,
    TranslationNamespaces.NAVIGATION
  ])

  const returnToAppUrl = useMemo(() => {
    // Check if we can send them to the bookings page based on their feature flags.
    if (hasRoutePermissions(Routes.BOOKINGS)) return Routes.BOOKINGS

    // Temporary support for navigating back to root rfps pages via the return
    // to app button. Just for the Global RFPS demo.
    if (hasRoutePermissions(Routes.RFPS)) return Routes.RFPS
    else if (hasRoutePermissions(Routes.ORGANIZATION_RFPS)) return Routes.ORGANIZATION_RFPS

    return legacyAppUrl
  }, [hasRoutePermissions, legacyAppUrl])

  const setupValueOptions = useSetupValueOptions()

  const items: SideNavItem[] = useMemo(() => {
    const defaultExpanded = Boolean(search && search.length > 0)

    return [
      {
        component: (
          <Box display="flex" flex="1" flexDirection="column">
            <Button
              startIcon={<ArrowBack />}
              variant="outlined"
              sx={{ color: (theme) => theme.palette.grey[200] }}
              LinkComponent={NextLink}
              href={returnToAppUrl}>
              Return to app
            </Button>
            <Box my={2}>
              <SearchInput
                onChange={(value) => setSearch(value)}
                value={search}
                variant="transparent"
              />
            </Box>
          </Box>
        )
      },
      {
        label: t(`${TranslationNamespaces.PERSONAL_SETTINGS}:personalSettings`),
        variant: 'header',
        showArrow: true,
        subItems: [
          {
            label: t(`${TranslationNamespaces.PERSONAL_SETTINGS}:userProfile.title`),
            icon: <AccountCircle />,
            href: Routes.SETTINGS_USER_PROFILE
          },
          {
            label: t(`${TranslationNamespaces.PERSONAL_SETTINGS}:email.title`),
            description: t(`${TranslationNamespaces.PERSONAL_SETTINGS}:email.description`),
            icon: <Mail />,
            href: Routes.SETTINGS_EMAIL,
            hidden: !hasRoutePermissions(Routes.SETTINGS_EMAIL)
          }
        ]
      },
      {
        label: selectedOrganization ? t('organization') : t('chain'),
        variant: 'header',
        showArrow: true,
        defaultExpanded,
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:setupOverview.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:setupOverview.description`),
            icon: <School />,
            href: Routes.SETTINGS_SETUP_OVERVIEW,
            hidden: !hasRoutePermissions(Routes.SETTINGS_SETUP_OVERVIEW)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:overview.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:overview.description`),
            icon: <Info />,
            href: Routes.SETTINGS_ORGANIZATION_OVERVIEW,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ORGANIZATION_OVERVIEW)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:preferences.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:preferences.description`),
            icon: <Tune />,
            href: Routes.SETTINGS_ORGANIZATION_PREFERENCES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ORGANIZATION_PREFERENCES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:usersAndRoles.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:usersAndRoles.description`),
            icon: <SupervisorAccount />,
            href: Routes.SETTINGS_USERS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_USERS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:subscription.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:subscription.description`),
            icon: <CreditCard />,
            href: Routes.SETTINGS_SUBSCRIPTION,
            hidden: !hasRoutePermissions(Routes.SETTINGS_SUBSCRIPTION)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:organizations.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:organizations.description`),
            icon: <LocationCity />,
            href: Routes.SETTINGS_ORGANIZATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ORGANIZATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:textMessaging.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:textMessaging.description`),
            icon: <Smartphone />,
            href: Routes.SETTINGS_TEXTING,
            hidden: !hasRoutePermissions(Routes.SETTINGS_TEXTING)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:billingSettings.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:billingSettings.description`),
            icon: <RequestQuote />,
            href: Routes.SETTINGS_BILLING_SETTINGS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_BILLING_SETTINGS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:taxes.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:taxes.description`),
            icon: <AttachMoney />,
            href: Routes.SETTINGS_TAXES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_TAXES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:pipelines.title`),
            icon: <LabelImportant />,
            href: Routes.SETTINGS_PIPELINES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_PIPELINES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:spaces.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:spaces.description`),
            icon: <MeetingRoom />,
            href: Routes.SETTINGS_SPACES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_SPACES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:rooms.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:rooms.description`),
            icon: <Hotel />,
            href: Routes.SETTINGS_ROOMS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ROOMS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:menus.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:menus.description`),
            icon: <Restaurant />,
            href: Routes.SETTINGS_MENUS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_MENUS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:packages.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:packages.description`),
            icon: <Inventory />,
            href: Routes.SETTINGS_PACKAGES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_PACKAGES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:budgets.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:budgets.description`),
            icon: <Calculate />,
            href: Routes.SETTINGS_BUDGETS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_BUDGETS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:workflowTemplates.title`),
            icon: <AccountTree />,
            href: Routes.SETTINGS_WORKFLOW_TEMPLATES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_WORKFLOW_TEMPLATES)
          }
        ]
      },
      {
        label: t('fields'),
        variant: 'header',
        showArrow: true,
        defaultExpanded,
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:customFields.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:customFields.description`),
            icon: <TextFields />,
            href: Routes.SETTINGS_CUSTOM_FIELDS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_CUSTOM_FIELDS)
          },
          ...setupValueOptions.map((option) => ({
            label: option.field_name,
            description: option.used_for.join(', '),
            href: `${Routes.SETTINGS_SETUP_VALUES}/${option.field_value}`,
            hidden: !hasRoutePermissions(Routes.SETTINGS_SETUP_VALUES),
            icon: option.icon
          }))
        ]
      },
      {
        label: t(`${TranslationNamespaces.NAVIGATION}:tools.title`),
        variant: 'header',
        showArrow: true,
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:tools.imports.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:tools.imports.description`),
            icon: <ImportExport />,
            href: Routes.SETTINGS_IMPORTS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_IMPORTS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:tools.intakeForms.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:tools.intakeForms.description`),
            icon: <DynamicForm />,
            href: Routes.SETTINGS_INTAKE_FORMS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_INTAKE_FORMS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:guestPortal.title`),
            icon: <Public />,
            href: Routes.SETTINGS_GUEST_PORTAL,
            hidden: !hasRoutePermissions(Routes.SETTINGS_GUEST_PORTAL)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:tools.roomPickups.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:tools.roomPickups.description`),
            icon: <RoomService />,
            href: Routes.SETTINGS_ROOM_PICKUPS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ROOM_PICKUPS)
          }
        ]
      },
      {
        label: t(`${TranslationNamespaces.NAVIGATION}:templates.title`),
        variant: 'header',
        showArrow: true,
        defaultExpanded: Boolean(search),
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.emailTemplates.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:templates.emailTemplates.description`
            ),
            href: Routes.SETTINGS_EMAIL_TEMPLATES,
            icon: <Mail />,
            hidden: !hasRoutePermissions(Routes.SETTINGS_EMAIL_TEMPLATES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.textTemplates.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:templates.textTemplates.description`
            ),
            href: Routes.SETTINGS_TEXT_TEMPLATES,
            icon: <Smartphone />,
            hidden: !hasRoutePermissions(Routes.SETTINGS_TEXT_TEMPLATES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.automationSequences.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:templates.automationSequences.description`
            ),
            href: Routes.SETTINGS_AUTOMATION_SEQUENCES,
            icon: <AutoAwesomeMotion />,
            hidden: !hasRoutePermissions(Routes.SETTINGS_AUTOMATION_SEQUENCES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.documentTemplates.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:templates.documentTemplates.description`
            ),
            href: Routes.SETTINGS_DOCUMENT_TEMPLATES,
            icon: <InsertDriveFile />,
            hidden: !hasRoutePermissions(Routes.SETTINGS_DOCUMENT_TEMPLATES)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.proposalTemplates.title`),
            href: Routes.SETTINGS_PROPOSAL_TEMPLATES,
            icon: <InsertDriveFile />,
            hidden: !hasRoutePermissions(Routes.SETTINGS_PROPOSAL_TEMPLATES)
          },

          {
            label: t(`${TranslationNamespaces.NAVIGATION}:templates.files.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:templates.files.description`),
            icon: <PermMedia />,
            href: Routes.SETTINGS_FILES,
            hidden: !hasRoutePermissions(Routes.SETTINGS_FILES)
          }
        ]
      },
      {
        label: t(`${TranslationNamespaces.NAVIGATION}:developer.title`),
        variant: 'header',
        showArrow: true,
        defaultExpanded,
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:developer.api.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:developer.api.description`),
            icon: <Api />,
            href: Routes.SETTINGS_API_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_API_INTEGRATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:developer.apps.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:developer.apps.description`),
            icon: <DeveloperBoard />,
            href: Routes.SETTINGS_OAUTH2_APPLICATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_OAUTH2_APPLICATIONS)
          }
        ]
      },
      {
        label: t(`${TranslationNamespaces.NAVIGATION}:integrations.title`),
        variant: 'header',
        showArrow: true,
        defaultExpanded,
        subItems: [
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.marketplace.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:integrations.marketplace.description`
            ),
            icon: <Apps />,
            href: Routes.SETTINGS_MARKETPLACE,
            hidden: !hasRoutePermissions(Routes.SETTINGS_MARKETPLACE)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.onlinePayment.title`),
            description: t(
              `${TranslationNamespaces.NAVIGATION}:integrations.onlinePayment.description`
            ),
            icon: <CreditCard />,
            href: Routes.SETTINGS_STRIPE_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_STRIPE_INTEGRATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.calendar.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:integrations.calendar.description`),
            icon: <EventAvailable />,
            href: Routes.SETTINGS_CALENDAR_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_CALENDAR_INTEGRATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.pms.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:integrations.pms.description`),
            icon: <Adjust />,
            href: Routes.SETTINGS_PMS_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_PMS_INTEGRATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.webhooks.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:integrations.webhooks.description`),
            icon: <Bolt />,
            href: Routes.SETTINGS_WEBHOOKS_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_WEBHOOKS_INTEGRATIONS)
          },
          {
            label: t(`${TranslationNamespaces.NAVIGATION}:integrations.zapier.title`),
            description: t(`${TranslationNamespaces.NAVIGATION}:integrations.zapier.description`),
            icon: <Link />,
            href: Routes.SETTINGS_ZAPIER_INTEGRATIONS,
            hidden: !hasRoutePermissions(Routes.SETTINGS_ZAPIER_INTEGRATIONS)
          }
        ]
      }
    ]
  }, [returnToAppUrl, search, t, hasRoutePermissions, selectedOrganization, setupValueOptions])

  const filteredItems = useMemo(
    () =>
      items
        .map((item) => {
          if (item.subItems) {
            // First check if the label of the category matches the search.
            if (item.label?.toLowerCase().includes(search.toLowerCase())) return item

            // If the category label doesn't match, check if any of the sub items match the search.
            return {
              ...item,
              subItems: item.subItems.filter((subItem) =>
                subItem?.label?.toLowerCase().includes(search.toLowerCase())
              )
            }
          }

          return item
        })
        .filter(
          (item) =>
            item.subItems?.length ||
            item.component || //  Don't filter out components
            item.label?.toLowerCase().includes(search.toLowerCase())
        ),
    [items, search]
  )

  return filteredItems
}

export default useSettingSideNavItems
