import React from 'react'

import { InfoRounded } from '@mui/icons-material'
import { Box, FormHelperText, FormLabel as MUIFormLabel, Tooltip } from '@mui/material'

import type { FormLabelProps } from 'components/forms/FormLabel/FormLabel.types'

const labelStyles = (disabled: boolean) => ({
  color: disabled ? 'text.disabled' : 'text.primary',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 0.5
})

const FormLabel = ({
  disabled,
  label,
  infoText,
  errorMessage,
  isError,
  helperText,
  ...props
}: FormLabelProps) => (
  <Box ml={0.25}>
    <MUIFormLabel sx={labelStyles(disabled)} {...props}>
      {label}
      {infoText && (
        <Box display="flex" alignItems="center">
          <Tooltip title={infoText} arrow>
            <InfoRounded color={disabled ? 'disabled' : 'action'} fontSize="small" />
          </Tooltip>
        </Box>
      )}
    </MUIFormLabel>
    {isError && errorMessage ? (
      <FormHelperText sx={{ m: '0' }} className="Mui-error">
        {errorMessage}
      </FormHelperText>
    ) : (
      helperText && (
        <FormHelperText sx={{ color: disabled ? 'text.disabled' : 'text.secondary', m: '0' }}>
          {helperText}
        </FormHelperText>
      )
    )}
  </Box>
)

export default FormLabel
