import type { ReactNode } from 'react'
import React, { forwardRef, useMemo, useRef } from 'react'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect
} from '@mui/material'

import ReadOnly from 'components/common/inputs/ReadOnly'
import type { SelectProps } from 'components/common/inputs/Select'

import type { ValueLabelPair, ValueNodePair } from '@repo/et-types'

const defaultNone = { label: 'None', value: '' }

const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      name,
      values,
      value,
      required,
      onChange,
      fullWidth,
      label,
      margin,
      disabled,
      multiple,
      helperText,
      errorMessage,
      size,
      variant = 'outlined',
      color,
      error,
      readOnly,
      shouldShowNone,
      ReadOnlyProps,
      ...props
    }: SelectProps,
    ref
  ): JSX.Element => {
    const id = `${name}-select`
    const formControlRef = useRef<HTMLDivElement>(null)

    const renderValue = (selected: string | number | (string | number)[]): string | ReactNode => {
      if (multiple && Array.isArray(selected)) {
        return selected
          ?.map((e: string | number) => values.find((v) => v.value === e)?.label)
          .join(', ')
      }

      const index = values.findIndex((e: ValueNodePair | ValueLabelPair) => e.value === selected)

      return index >= 0 ? values[index].label : shouldShowNone || readOnly ? defaultNone.label : ''
    }

    const finalValues = useMemo(
      () => (shouldShowNone || readOnly ? [defaultNone, ...values] : values),
      [shouldShowNone, values, readOnly]
    )

    if (readOnly) {
      const readOnlyValue =
        multiple && Array.isArray(value)
          ? value?.map((e: string | number) => values.find((v) => v.value === e)?.label)
          : values.find((v) => v.value === value)?.label

      return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
    }

    return (
      <FormControl
        ref={formControlRef}
        color={color}
        disabled={disabled}
        margin={margin}
        error={error}
        fullWidth={fullWidth}
        required={required}
        variant={variant}
        size={size}
        focused={readOnly || undefined}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {/* @ts-expect-error -- MUISelect seems to shove "unknown" always into the value type, which breaks type checks. */}
        <MuiSelect<string | number | (string | number)[]>
          {...props}
          ref={ref}
          readOnly={readOnly}
          displayEmpty={readOnly || props.displayEmpty}
          data-testid={id}
          sx={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          inputProps={{ id }}
          labelId={id}
          label={label}
          onChange={onChange}
          value={value || (multiple ? [] : '')}
          multiple={multiple}
          MenuProps={{
            PaperProps: { style: { maxHeight: '18rem' } },
            MenuListProps: { style: { width: formControlRef.current?.offsetWidth } }
          }}
          IconComponent={readOnly ? undefined : props.IconComponent}
          renderValue={renderValue}>
          {finalValues.map((e: ValueNodePair | ValueLabelPair, index: number) => (
            <MenuItem
              key={index}
              value={e.value}
              disabled={e.disabled}
              data-testid={`${id}-option-${index}`}
              role="option">
              <ListItemText
                sx={{ whiteSpace: 'normal' }}
                primary={e.label}
                secondary={e.helperText}
              />
            </MenuItem>
          ))}
        </MuiSelect>
        {errorMessage ? (
          <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>
        ) : (
          helperText && <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  }
)

Select.displayName = 'Select'

export default Select
