import React from 'react'

import { MultiInputTimeRangeField, SingleInputTimeRangeField } from '@mui/x-date-pickers-pro'

import { format, isValid } from 'date-fns'

import type { TimeRangeFieldProps } from 'components/common/date/TimeRangeField'
import ReadOnly from 'components/common/inputs/ReadOnly'

import type { DateRange, PickerValidDate } from '@mui/x-date-pickers-pro'

const TimeRangeField = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  multiInput = false,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: TimeRangeFieldProps) => {
  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue[0]) && !isValid(readOnlyValue[1])) return ''

      if (readOnlyFormatter) return readOnlyFormatter(readOnlyValue)

      const firstValue = isValid(readOnlyValue[0]) ? format(readOnlyValue[0], 'h:mm a') : ''
      const secondValue = isValid(readOnlyValue[1]) ? format(readOnlyValue[1], 'h:mm a') : ''

      return `${firstValue} - ${secondValue}`
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  const Component = multiInput ? MultiInputTimeRangeField : SingleInputTimeRangeField

  return (
    // @ts-expect-error -- We are passing the correct props to the component
    // Typescript doesn't like the conditional types on the props.
    <Component
      {...props}
      value={value || ([null, null] as DateRange<PickerValidDate>)}
      label={label}
      slotProps={{
        ...slotProps,
        textField: (state) => ({
          error,
          helperText: error ? errorMessage : helperText,
          fullWidth,
          ...(typeof slotProps?.textField === 'function'
            ? slotProps.textField(state)
            : slotProps?.textField)
        })
      }}
    />
  )
}

export default TimeRangeField
