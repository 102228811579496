import React from 'react'

import { isValid, parseISO } from 'date-fns'
import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'
import type { FormTimePickerProps } from 'components/forms/FormTimePicker/FormTimePicker.types'

const FormTimePicker = ({
  name,
  required,
  slotProps,
  defaultValue: propDefaultValue,
  ...props
}: FormTimePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const defaultValue = propDefaultValue || lodashGet(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const dateValue = () => {
          // Handle when value is a date
          if (isValid(value)) return value

          return value && isValid(parseISO(value.toString())) ? parseISO(value) : null
        }

        return (
          <TimePicker
            {...props}
            inputRef={ref}
            name={name}
            error={isError}
            errorMessage={errorMessage}
            defaultValue={dateValue()}
            onChange={onChange}
            slotProps={{ ...slotProps, textField: { onBlur, ...slotProps?.textField } }}
            value={dateValue()}
          />
        )
      }}
    />
  )
}

export default FormTimePicker
