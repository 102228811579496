import React from 'react'

import { DateRangePicker as MUIDateRangePicker } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import type { DateRangePickerProps } from 'components/common/date/DateRangePicker'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDate } from 'hooks/useFormatDate'

import type { DateRange, PickerValidDate } from '@mui/x-date-pickers-pro'

const DateRangePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DateRangePickerProps) => {
  const { format, formatDate } = useFormatDate()

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue[0]) && !isValid(readOnlyValue[1])) return ''

      if (readOnlyFormatter) return readOnlyFormatter(readOnlyValue)

      const firstValue = isValid(readOnlyValue[0]) ? formatDate(readOnlyValue[0], format) : ''
      const secondValue = isValid(readOnlyValue[1]) ? formatDate(readOnlyValue[1], format) : ''

      return `${firstValue} - ${secondValue}`
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUIDateRangePicker
      value={value || ([null, null] as DateRange<PickerValidDate>)}
      label={label}
      format={format}
      {...props}
      slotProps={{
        ...slotProps,
        textField: (state) => ({
          error,
          helperText: error ? errorMessage : helperText,
          fullWidth,
          ...(typeof slotProps?.textField === 'function'
            ? slotProps.textField(state)
            : slotProps?.textField)
        })
      }}
    />
  )
}

export default DateRangePicker
