import React, { useMemo } from 'react'

import { Close } from '@mui/icons-material'
import { Grid2 as Grid, IconButton, Paper, Typography } from '@mui/material'

import type { DialogFormHeaderProps } from 'components/forms/DialogForm/DialogForm.types'

import type { Theme } from '@mui/material/styles'

import { DIALOG_TYPES } from 'types/dialog'
/**
 * The header component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogFormHeader = ({
  headerLabel = undefined,
  variant = DIALOG_TYPES.DRAWER,
  onClose
}: DialogFormHeaderProps) => {
  const padding = useMemo(() => {
    if (variant === DIALOG_TYPES.DRAWER) return { px: 4, py: 2 }

    return { px: 2, py: 2 }
  }, [variant])

  const headerStyles = (theme: Theme) => ({
    bgcolor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1200,
    justifyContent: 'space-between',
    alignItems: 'center',
    ...padding
  })

  const headerContent = useMemo(() => {
    if (!headerLabel) return null

    const isArray = Array.isArray(headerLabel)

    if (!isArray && typeof headerLabel === 'string') {
      return <Typography variant="h5">{headerLabel}</Typography>
    } else if (!isArray) return <Typography variant="h5">{headerLabel}</Typography>

    return (
      <Grid container direction="column">
        {headerLabel.map((value, index) => (
          <Grid key={index}>
            <Typography
              variant={index === headerLabel.length - 1 ? 'h5' : 'caption'}
              color={index === headerLabel.length - 1 ? 'text.primary' : 'text.secondary'}>
              {value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    )
  }, [headerLabel])

  return (
    <Grid
      container
      component={Paper}
      sx={headerStyles}
      variant="outlined"
      square
      data-testid="DialogFormHeader">
      {headerContent}
      {onClose && (
        <Grid>
          <IconButton data-testid="DialogFormCloseButton" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default DialogFormHeader
